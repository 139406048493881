<template>
  <b-container>
    <router-link
      class="d-block"
      :to="{
        name: 'landing-page.category-detail',
        query: { movieId: episodeData.id },
      }"
    >
      <b-row class="pb-4">
        <b-col cols="12" md="6" class="text-right pb-2">
          <div class="epi-box">
            <div class="epi-img">
              <img :data-src="episodeData.image" class="img-fluid img-zoom lazyload" alt="" style="width: 100%; height: auto;" />
              <div class="episode-play-info">
                <div class="episode-play">
                  <i class="ri-play-fill"></i>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" class="pb-2">
          <div class="video-text-container">
            <h4 class="episode-title mb-2">{{ episodeData.title }}</h4>
            <p class="text-white m-0">Publicación: {{ episodeData.date }}</p>
            <p class="text-white mb-2">Duración: {{ episodeData.time }}</p>
            <p class="text-white mb-0 thumb-desc">
              {{
                episodeData.description.length > 100
                  ? episodeData.description.slice(0, 100) + "..."
                  : episodeData.description
              }}
            </p>
          </div>
        </b-col>
      </b-row>
    </router-link></b-container
  >
</template>
<script>
export default {
  name: "VideoThumbnail",
  props: {
    episodeData: Object
  }
}
</script>
<style>
.episode-title {
  transition: 1s all;
  color: var(--iq-primary);
}

.epi-box {
  width: 100%;
  padding-top: 0;
  position: relative;
  max-width: 100%;
}

.thumb-desc {
  display: block;
}

@media (max-width: 500px) {
  .episode-title {
    font-size: 1rem;
  }
}
</style>
