<template>
  <div class="all-videos">
    <b-container class="bv-example-row">
      <b-row class="justify-content-md-center align-items-center">
        <b-col cols="2" md="auto">Ordenar por: </b-col>
        <b-col cols="12" md="auto">
          <b-button-group>
            <button class="btn btn-hover mr-2" v-on:click="OrderByViews">
              Vistas
            </button>
            <button class="btn btn-hover mr-2" v-on:click="OrderByDate">
              Fecha
            </button>
            <button class="btn btn-hover mr-2" v-on:click="OrderByName">
              Nombre
            </button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12">
          <VideoThumbnail v-for="(item, index) in videos" :key="index" :episodeData="item">
          </VideoThumbnail>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import VideoThumbnail from "./AllVideos/VideoThumbnail.vue"
import firebase from "firebase/app"
import dateUtilities from "../../Utils/date-utilities.js"
import "firebase/firestore"

const db = firebase.firestore()

export default {
  name: "AllVideos",
  components: { VideoThumbnail },
  props: ["episodesBySearch", "filterBySearch"],
  data() {
    return {
      videos: []
    }
  },
  async mounted() {
    this.videos = this.filterBySearch
      ? this.episodesBySearch
      : await this.GetAllVideos()

    this.MapVideos(this.videos)
  },
  methods: {
    MapVideos(videos) {
      return videos.map((video) => this.ParseDateOnVideo(video))
    },
    GetAllVideos() {
      return this.$store
        .dispatch("Category/getAllVideos", {
          db,
          firebase
        })
        .then((querySnapshot) => {
          const videos = []
          querySnapshot.forEach((doc) => {
            const video = doc.data()
            videos.push(video)
          })

          return videos
        })
    },
    ParseDateOnVideo(videoInfo) {
      videoInfo.date = dateUtilities.firebaseDateParse(videoInfo.fecha)
      return videoInfo
    },
    OrderByViews() {
      this.videos.sort(function (a, b) {
        if (a.visualizations < b.visualizations) {
          return 1
        }
        if (a.visualizations > b.visualizations) {
          return -1
        }
        // a must be equal to b
        return 0
      })
    },
    OrderByDate() {
      this.videos.sort(function (a, b) {
        if (a.date > b.date) {
          return 1
        }
        if (a.date < b.date) {
          return -1
        }
        // a must be equal to b
        return 0
      })
    },
    OrderByName() {
      this.videos.sort(function (a, b) {
        if (a.title > b.title) {
          return 1
        }
        if (a.title < b.title) {
          return -1
        }
        // a must be equal to b
        return 0
      })
    }
  }
}
</script>
<style>
.all-videos {
  max-width: 90%;
  margin: auto;
}
</style>
