<template>
  <b-container fluid>
    <h4>Resultados para: {{ querySearch }}</h4>
    <AllVideos v-if="results.length > 0" :filterBySearch="true" :episodesBySearch="results"></AllVideos>
    <p v-else>Tu búsqueda para {{ querySearch }} no arrojó resultados</p>
  </b-container>
</template>

<script>
import firebase from "firebase/app"
import "firebase/firestore"
import "instantsearch.css/themes/satellite-min.css"
import index from "../../algolia/index"
import AllVideos from "./AllVideos.vue"

const db = firebase.firestore()

export default {
  name: "Search",
  components: {
    AllVideos
  },
  mounted() {
    this.querySearch = this.$route.query.video
    this.performSearch(this.querySearch).then((v) => {
      console.log(this.results)
    })
  },
  data() {
    return {
      querySearch: "",
      resultsTemp: [],
      results: [],
      finishedSearch: false
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.querySearch = to.query.video
    this.performSearch(this.querySearch).then((v) => {
      console.log(this.results)
      next()
    })
  },
  methods: {
    async performSearch(value) {
      this.resultsTemp = await index.search(value, {
        hitsPerPage: 5
      })
      const resultLength = this.resultsTemp.nbHits // Número de objetos en el array
      if (resultLength > 0) {
        this.results = []
        for (let index = 0; index < resultLength; index++) {
          const titleSearch = this.resultsTemp.hits[index].title
          const idSearch = this.resultsTemp.hits[index].id
          if (titleSearch) {
            this.getVideo(idSearch, (video) => {
              console.log(video)
              this.results.push(video)
            })
          }
        }
      } else {
        console.log("resultados 0")
      }
    },
    getVideo(videoId, getVideoCallback) {
      return this.$store
        .dispatch("Category/getVideo", {
          db,
          firebase,
          id: videoId
        })
        .then((querySnapshot) => {
          let videoInfo = {}

          querySnapshot.forEach((doc) => {
            videoInfo = doc.data()
          })

          getVideoCallback(videoInfo)
        })
    }
  }
}
</script>
